<template>
  <div>
    <div class="track-player">
      <speed @onSpeedChange="handleSpeedChange" />
      <div class="track-play">
        <a-icon :type="status === 1 ? 'pause' : 'play-circle'" class="track-play-icon" @click="handlePlay" />
        <a-slider :min="0" :max="currentTrackPoints.length" :default-value="0" class="track-slider" @afterChange="handleSliderAfterChange" :tipFormatter="getWorkTime" v-model="position" />
      </div>
    </div>
    <div class="track-info">
      <component :is="currentTrackInfoWindowComponent.component" :object-info="objectInfo" :track-info="trackInfoEx" :show-footer="false" />
    </div>
  </div>
</template>
<script>
import Speed from '@/views/monitor/monitorComponents/mapHistoryTrack/speed.vue'
import { Slider } from 'ant-design-vue'
import { GetTrackInfoWindowConfig } from '../../config/infoWindowConfig'
import { GetMapAddress } from '../../utils/mapUtil'
export default {
  components: {
    Speed,
    ASlider: Slider
  },
  props: {
    mapObj: {
      type: Object,
      default: () => {}
    },
    objectInfo: {
      type: Object,
      default: () => {}
    },
    moduleKey: {
      type: String,
      default: () => {}
    },
    currentTrackPoints: {
      type: Array,
      default: () => {}
    }
  },
  data() {
    return {
      // 状态： 0 初始状态，1正在运动，2停止
      status: 0,
      position: 0,
      trackInfo: {},
      trackInfoEx: {},
      currentTrackInfoWindowComponent: {}
    }
  },
  watch: {
    currentTrackPoints(newVal, oldVal) {
      this.trackInfo = newVal && newVal.length > 0 ? newVal[0] : {}
    },
    trackInfo(newVal, oldVal) {
      console.log('setMapAddress')
      if (!newVal) {
        return
      }
      const { mapGeo, BMap } = this.mapObj
      const { lng, lat } = newVal
      if (!lng && !lat) {
        return
      }
      GetMapAddress(mapGeo, BMap, lng, lat, (address) => {
        newVal.address = address
        this.trackInfoEx = newVal
      })
    }
  },
  created() {
    this.setTrackInfoWindow()
  },
  mounted() {
    this.initPubSub()
    this.trackInfo = this.currentTrackPoints && this.currentTrackPoints.length > 0 ? this.currentTrackPoints[0] : {}
  },
  methods: {
    // 手动触发播放
    handlePlay() {
      console.log('handlePlay')
      const oldStatus = this.status
      if (this.status === 0 || this.status === 2) {
        // 当为初始状态与停止状态，点击即为运动中
        this.status = 1
      } else if (this.status === 1) {
        // 当状态为1，点击即为停止状态
        this.status = 2
      }
      // 播放历史轨迹
      this.$emit('mapTrackPlay', this.status, oldStatus)
      // this.mapTrackPlay(this.status, oldStatus)
      if (this.position === this.currentTrackPoints.length) {
        this.position = 0
      }
    },
    handleSpeedChange(val) {
      this.$emit('mapSetTrackPlaySpeed', val)
      // this.mapSetTrackPlaySpeed(val)
    },
    handleSliderAfterChange(val) {
      console.log(val)
      // 轨迹播放跳转, 注意数组索引问题
      if (val > 0) {
        val = val - 1
      }
      // this.mapTrackPlayJumpTo(val, this.status)
      this.$emit('mapTrackPlayJumpTo', val, this.status)
    },
    initPubSub() {
      this.tokenFinish = window.PubSub.subscribe('end', (msg, flag) => {
        if (flag) {
          this.position = 0
          // 运动结束，状态为初始值
          if (this.status !== 2) {
            this.status = 0
          }
        }
      })

      // 订阅移动一步
      this.token = window.PubSub.subscribe('step', (msg, i) => {
        if (this.status === 1) {
          this.position++
          this.trackInfo = this.currentTrackPoints[this.position]
        }
      })
      this.tokenSlide = window.PubSub.subscribe('slide', (msg, i) => {
        console.log('slide', i)
        this.position = i
        this.trackInfo = this.currentTrackPoints[i]
      })
    },
    setTrackInfoWindow() {
      const trackInfoWindowModules = GetTrackInfoWindowConfig()[0].modules
      if (!this.currentTrackInfoWindowComponent.component) {
        const tmpArr = trackInfoWindowModules.filter((p) => p.id === this.moduleKey)
        if (tmpArr.length === 1) {
          console.log('找到轨迹信息组件配置', this.moduleKey)
          this.currentTrackInfoWindowComponent = tmpArr[0]
        } else {
          console.log('找不轨迹信息组件配置', this.moduleKey)
          this.currentTrackInfoWindowComponent = {}
        }
      }
    },
    setMapAddress() {
      console.log('setMapAddress')
      const { mapGeo, BMap } = this.mapObj
      const { lng, lat } = this.trackInfo
      GetMapAddress(mapGeo, BMap, lng, lat, (address) => {
        this.trackInfo.address = address
      })
    }
  }
}
</script>
<style lang="less" scoped>
.track-player {
  display: flex;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  span {
    flex: 1;
  }
  span:first-child {
    padding-right: 10px;
  }
  .track-play {
    display: flex;
    flex: 1;
    margin-left: 5px;
    justify-items: center;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    padding-right: 5px;
    .track-play-icon {
      flex: 0 0 50px;
      min-width: 50px;
      align-self: center;
      font-size: 26px;
    }
    .track-slider {
      flex: 1;
    }
  }
}
.track-info {
  margin-top: 20px;
  margin-bottom: 0;
}
</style>
