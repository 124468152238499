var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "track-player" },
      [
        _c("speed", { on: { onSpeedChange: _vm.handleSpeedChange } }),
        _c(
          "div",
          { staticClass: "track-play" },
          [
            _c("a-icon", {
              staticClass: "track-play-icon",
              attrs: { type: _vm.status === 1 ? "pause" : "play-circle" },
              on: { click: _vm.handlePlay },
            }),
            _c("a-slider", {
              staticClass: "track-slider",
              attrs: {
                min: 0,
                max: _vm.currentTrackPoints.length,
                "default-value": 0,
                tipFormatter: _vm.getWorkTime,
              },
              on: { afterChange: _vm.handleSliderAfterChange },
              model: {
                value: _vm.position,
                callback: function ($$v) {
                  _vm.position = $$v
                },
                expression: "position",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "track-info" },
      [
        _c(_vm.currentTrackInfoWindowComponent.component, {
          tag: "component",
          attrs: {
            "object-info": _vm.objectInfo,
            "track-info": _vm.trackInfoEx,
            "show-footer": false,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }