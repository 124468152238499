// 对象信息弹窗组件
export const GetObjectInfoWindowConfig = () => {
  return [
    {
      modules: [
        {
          id: 'cleaner',
          component: () => import('../mapInfoWindow/cleaner/department/object')
        },
        {
          id: 'cleanerZone',
          component: () => import('../mapInfoWindow/cleaner/department/object')
        },
        {
          id: 'traditionalVehicle',
          component: () => import('../mapInfoWindow/traditional/vehicle/object')
        },
        {
          id: 'vehicle',
          component: () => import('../mapInfoWindow/vehicle/object')
        }
      ]
    }
  ]
}
// 历史轨迹弹窗组件
export const GetTrackInfoWindowConfig = () => {
  return [
    {
      modules: [
        {
          id: 'cleaner',
          component: () => import('../mapInfoWindow/cleaner/department/track')
        },
        {
          id: 'cleanerZone',
          component: () => import('../mapInfoWindow/cleaner/department/track')
        },
        {
          id: 'traditionalVehicle',
          component: () => import('../mapInfoWindow/traditional/vehicle/track')
        },
        {
          id: 'vehicle',
          component: () => import('../mapInfoWindow/vehicle/track')
        }
      ]
    }
  ]
}

// 围栏弹窗组件
export const GetObjectInfoWindowFenceConfig = () => {
  return [
    {
      modules: [
        {
          id: 'cleanerZone',
          component: () => import('../mapInfoWindow/cleaner/zone')
        },
        {
          id: 'traditionalVehicle',
          component: () => import('../mapInfoWindow/traditional/vehicle/object')
        }
      ]
    }
  ]
}
